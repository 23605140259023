const carol = `I have endeavoured in this Ghostly little book, to raise the Ghost of an Idea, which shall not put my readers out of humour with themselves, with each other, with the season, or with me. May it haunt their houses pleasantly, and no one wish to lay it.

Their faithful Friend and Servant, C. D. December, 1843.

Stave 1: Marley's Ghost

Marley was dead: to begin with. There is no doubt whatever about that. The register of his burial was signed by the clergyman, the clerk, the undertaker, and the chief mourner. Scrooge signed it. And Scrooge's name was good upon 'Change, for anything he chose to put his hand to.

Old Marley was as dead as a door-nail.

Mind! I don't mean to say that I know, of my own knowledge, what there is particularly dead about a door-nail. I might have been inclined, myself, to regard a coffin-nail as the deadest piece of ironmongery in the trade. But the wisdom of our ancestors is in the simile; and my unhallowed hands shall not disturb it, or the Country's done for. You will therefore permit me to repeat, emphatically, that Marley was as dead as a door-nail.

Scrooge knew he was dead? Of course he did. How could it be otherwise? Scrooge and he were partners for I don't know how many years. Scrooge was his sole executor, his sole administrator, his sole assign, his sole residuary legatee, his sole friend, and sole mourner. And even Scrooge was not so dreadfully cut up by the sad event, but that he was an excellent man of business on the very day of the funeral, and solemnised it with an undoubted bargain.

The mention of Marley's funeral brings me back to the point I started from. There is no doubt that Marley was dead. This must be distinctly understood, or nothing wonderful can come of the story I am going to relate. If we were not perfectly convinced that Hamlet's Father died before the play began, there would be nothing more remarkable in his taking a stroll at night, in an easterly wind, upon his own ramparts, than there would be in any other middle-aged gentleman rashly turning out after dark in a breezy spot -- say Saint Paul's Churchyard for instance -- literally to astonish his son's weak mind.

Scrooge never painted out Old Marley's name. There it stood, years afterwards, above the warehouse door: Scrooge and Marley. The firm was known as Scrooge and Marley. Sometimes people new to the business called Scrooge Scrooge, and sometimes Marley, but he answered to both names. It was all the same to him.

Oh! But he was a tight-fisted hand at the grind- stone, Scrooge! a squeezing, wrenching, grasping, scraping, clutching, covetous, old sinner! Hard and sharp as flint, from which no steel had ever struck out generous fire; secret, and self-contained, and solitary as an oyster. The cold within him froze his old features, nipped his pointed nose, shrivelled his cheek, stiffened his gait; made his eyes red, his thin lips blue

and spoke out shrewdly in his grating voice. A frosty rime was on his head, and on his eyebrows, and his wiry chin. He carried his own low temperature always about with him; he iced his office in the dogdays; and didn't thaw it one degree at Christmas.

External heat and cold had little influence on Scrooge. No warmth could warm, no wintry weather chill him. No wind that blew was bitterer than he, no falling snow was more intent upon its purpose, no pelting rain less open to entreaty. Foul weather didn't know where to have him. The heaviest rain, and snow, and hail, and sleet, could boast of the advantage over him in only one respect. They often 'came down' handsomely, and Scrooge never did.

Nobody ever stopped him in the street to say, with gladsome looks, 'My dear Scrooge, how are you? When will you come to see me?' No beggars implored him to bestow a trifle, no children asked him what it was o'clock, no man or woman ever once in all his life inquired the way to such and such a place, of Scrooge. Even the blind men's dogs appeared to know him; and when they saw him coming on, would tug their owners into doorways and up courts; and then would wag their tails as though they said, 'No eye at all is better than an evil eye, dark master!'

But what did Scrooge care! It was the very thing he liked. To edge his way along the crowded paths of life, warning all human sympathy to keep its distance, was what the knowing ones call 'nuts' to Scrooge.

Once upon a time -- of all the good days in the year, on Christmas Eve -- old Scrooge sat busy in his counting-house. It was cold, bleak, biting weather: foggy withal: and he could hear the people in the court outside, go wheezing up and down, beating their hands upon their breasts, and stamping their feet upon the pavement stones to warm them. The city clocks had only just gone three, but it was quite dark already -- it had not been light all day -- and candles were flaring in the windows of the neighbouring offices, like ruddy smears upon the palpable brown air. The fog came pouring in at every chink and keyhole, and was so dense without, that although the court was of the narrowest, the houses opposite were mere phantoms. To see the dingy cloud come drooping down, obscuring everything, one might have thought that Nature lived hard by, and was brewing on a large scale.

The door of Scrooge's counting-house was open that he might keep his eye upon his clerk, who in a dismal little cell beyond, a sort of tank, was copying letters. Scrooge had a very small fire, but the clerk's fire was so very much smaller that it looked like one coal. But he couldn't replenish it, for Scrooge kept the coal-box in his own room; and so surely as the clerk came in with the shovel, the master predicted that it would be necessary for them to part. Wherefore the clerk put on his white comforter, and tried to warm himself at the candle; in which effort, not being a man of a strong imagination, he failed.

'A merry Christmas, uncle! God save you!' cried a cheerful voice. It was the voice of Scrooge's nephew, who came upon him so quickly that this was the first intimation he had of his approach.

'Bah!' said Scrooge, 'Humbug!'

He had so heated himself with rapid walking in the fog and frost, this nephew of Scrooge's, that he was all in a glow; his face was ruddy and handsome; his eyes sparkled, and his breath smoked again.

'Christmas a humbug, uncle!' said Scrooge's nephew. 'You don't mean that, I am sure?'

'I do,' said Scrooge. 'Merry Christmas! What right have you to be merry? What reason have you to be merry? You're poor enough.'

'Come, then,' returned the nephew gaily. 'What right have you to be dismal? What reason have you to be morose? You're rich enough.'

Scrooge having no better answer ready on the spur of the moment, said 'Bah!' again; and followed it up with 'Humbug.'

'Don't be cross, uncle!' said the nephew.

'What else can I be,' returned the uncle, 'when I live in such a world of fools as this? Merry Christmas! Out upon merry Christmas! What's Christmas time to you but a time for paying bills without money; a time for finding yourself a year older, but not an hour richer; a time for balancing your books and having every item in 'em through a round dozen of months presented dead against you? If I could work my will,' said Scrooge indignantly, 'every idiot who goes about with "Merry Christmas" on his lips, should be boiled with his own pudding, and buried with a stake of holly through his heart. He should!'

'Uncle!' pleaded the nephew.

'Nephew!' returned the uncle sternly, 'keep Christmas in your own way, and let me keep it in mine.'

'Keep it!' repeated Scrooge's nephew. 'But you don't keep it.'

'Let me leave it alone, then,' said Scrooge. 'Much good may it do you! Much good it has ever done you!'

'There are many things from which I might have derived good, by which I have not profited, I dare say,' returned the nephew. 'Christmas among the rest. But I am sure I have always thought of Christmas time, when it has come round -- apart from the veneration due to its sacred name and origin, if anything belonging to it can be apart from that -- as a good time; a kind, forgiving, charitable, pleasant time: the only time I know of, in the long calendar of the year, when men and women seem by one consent to open their shut-up hearts freely, and to think of people below them as if they really were fellow-passengers to the grave, and not another race of creatures bound on other journeys. And therefore, uncle, though it has never put a scrap of gold or silver in my pocket, I believe that it has done me good, and will do me good; and I say, God bless it!'

The clerk in the Tank involuntarily applauded. Becoming immediately sensible of the impropriety, he poked the fire, and extinguished the last frail spark for ever.

'Let me hear another sound from you,' said Scrooge, 'and you'll keep your Christmas by losing your situation! You're quite a powerful speaker, sir,' he added, turning to his nephew. 'I wonder you don't go into Parliament.'

'Don't be angry, uncle. Come! Dine with us tomorrow.'

Scrooge said that he would see him -- yes, indeed he did. He went the whole length of the expression, and said that he would see him in that extremity first.

'But why?' cried Scrooge's nephew. 'Why?'

'Why did you get married?' said Scrooge.

'Because I fell in love.'

'Because you fell in love!' growled Scrooge, as if that were the only one thing in the world more ridiculous than a merry Christmas. 'Good afternoon!'

'Nay, uncle, but you never came to see me before that happened. Why give it as a reason for not coming now?'

'Good afternoon,' said Scrooge.

'I want nothing from you; I ask nothing of you; why cannot we be friends?'

'Good afternoon,' said Scrooge.

'I am sorry, with all my heart, to find you so resolute. We have never had any quarrel, to which I have been a party. But I have made the trial in homage to Christmas, and I'll keep my Christmas humour to the last. So A Merry Christmas, uncle!'

'Good afternoon,' said Scrooge.

'And A Happy New Year!'

'Good afternoon,' said Scrooge.`;

export default carol;
