import React, { useState } from 'react';
import './App.css';
//import Console from 'react-console';
//import Console from './components/console';
import Console from 'simple-react-console';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import carol from './AChristmasCarol';

function App() {
  const style = a11yLight;

  const [passiveOutput] = useState({
    string: carol,
  });
  const [demo_st, setDemo_st] = useState({ string: 'What is your name?', id: 'name' });
  const [userAge, setUserAge] = useState(0);
  const demog_onResponse = (response) => {
    switch (response.id) {
      case 'name':
        setDemo_st([
          { string: 'Hello ' + response.value + '!' },
          { string: 'What is your age?', id: 'age', type: 'number', max: 2 },
        ]);
        break;
      case 'age':
        if (response.value === '') {
          setDemo_st([
            {
              string: "Don't want to tell me eh? No worries.",
            },
            { string: 'Have a nice day' },
          ]);
          return;
        }
        const n = parseInt(response.value);
        setUserAge(n);
        if (n <= 0) {
          setDemo_st([
            { string: 'Your only ' + response.value + ' years old! Are you sure?' },
            { string: 'What is your age?', id: 'age', type: 'number', max: 2 },
          ]);
        } else if (n < 5) {
          setDemo_st([
            { string: 'Wow, your only ' + response.value + ' and your looking up npm packages!' },
            { string: 'Your some pup! are you sure you are only ' + response.value },
            { string: 'What is your age?', id: 'age', type: 'number', max: 2 },
          ]);
        } else {
          setDemo_st([
            { string: response.value + ' years old! Do you want to see your age in binary?' },
            { string: "if so, type 'yes' and press enter", id: 'binary', max: 3 },
          ]);
        }

        break;
      case 'binary':
        if (response.value.toLowerCase() === 'yes') {
          const b = binaryConversition(userAge);
          setDemo_st([{ string: userAge + ' in binary is: ' }, { string: b }]);
        } else {
          setDemo_st([
            { string: 'Oh! ok, are you sure?' },
            { string: "Type 'yes' and press enter if you change your mind", id: 'binary', max: 3 },
          ]);
        }
        break;

      default:
        setDemo_st({ string: 'What is your name?', id: 'name' });
        break;
    }
  };

  const binaryConversition = (num) => {
    let binary = '';
    while (num > 0) {
      binary = (num % 2) + binary;
      num = Math.floor(num / 2);
    }
    return binary;
  };

  const [controlsString] = useState([
    { string: '******************************************************' },
    { string: '********************CONTROLS**********************' },
    { string: '******************************************************' },
    { string: ' ' },
    { string: 'Scrollbars' },
    { string: 'You can enable or disable the scrollbar.' },
    { string: 'When it is enabled, if the lines of output grow larger' },
    { string: 'than the console, a scrollbar will appear.' },
    { string: ' ' },
    { string: 'Looping' },
    { string: 'When using an array for your setOutput you can enable looping' },
    { string: 'When enabled, once all lines of the array are shown, the console' },
    { string: 'will loop back to the start and continue to output to the console' },
    { string: ' ' },
    { string: 'Speed' },
    { string: 'You can modify the speed at which the output animations runs.' },
    { string: 'By default it is set to 50, if you decrease the number it ' },
    { string: 'will speed up. If you increase the number it will speed up' },
    { string: ' ' },
    { string: '  ' },
  ]);

  const [setOutputArray_string] = useState([
    { string: 'Here is line one - 1.' },
    { string: 'Here is line two - 2.' },
    { string: 'Here is line three - 3.' },
  ]);
  const [onCompleteDemo_string, setOnCompleteDemo_string] = useState(
    'After this line completes an onComplete event is triggered 0',
  );
  const [onCompleteDemo_n, setOnCompleteDemo_n] = useState(0);
  const onCompleteDemo = () => {
    const n = onCompleteDemo_n + 1;
    if (n <= 5 && n > 0) {
      setOnCompleteDemo_n(n);
      setOnCompleteDemo_string('After this line completes an onComplete event is triggered ' + n);
    } else if (n > 0) {
      setOnCompleteDemo_n(-1);
      setOnCompleteDemo_string("Type 'yes' and press enter to restart");
    }
  };
  const onCompleteDemoResponse = (ev) => {
    if (ev && ev.value && ev.value.toLowerCase() === 'yes') {
      setOnCompleteDemo_n(0);
      setOnCompleteDemo_string('After this line completes an onComplete event is triggered ' + 0);
    }
  };

  const [onResponse_output, onResponse_setOutput] = useState({
    string: 'Hello, what is your name?',
    id: 'name',
  });
  const onResponse_onResponse = (response) => {
    console.log('response id: ' + response.id);
    console.log('response value: ' + response.value);
    if (response.id === 'name') {
      onResponse_setOutput('Hello, ' + response.value);
    }
  };

  const [inputTypes, setInputTypes] = useState({
    string: 'You can only enter numbers in the following input',
    type: 'number',
    id: 'numbers',
  });
  const inputTypesOnResponse = (response) => {
    switch (response.id) {
      case 'numbers':
        setInputTypes({
          string: 'You can set the max chars - max = 5',
          max: 5,
          id: 'max',
        });
        break;
      case 'max':
        setInputTypes({
          string: 'You can set them both',
          max: 2,
          type: 'number',
          id: 'done',
        });
        break;
      default:
        break;
    }
  };

  return (
    <div className="App">
      <div className="left-brace"></div>
      <div className="central-column">
        <h1>simple-react-console</h1>
        <div className="description-text">
          {' '}
          <b>simple-react-console</b> is a simple console with animated text output. It can be used
          to get input from your users or to show information. <b>simple-react-console</b> started
          life as a prototype of a puzzle game. It has been pulled out and packaged up in the hope
          that it may be useful to others.
        </div>
        <Console
          width="800px"
          height="350px"
          showHeader={true}
          shadow={true}
          focus={true}
          setOutput={demo_st}
          onResponse={demog_onResponse}
        />
        <h2>Installation</h2>
        <Console
          setOutput={'npm install simple-react-console'}
          showHeader={true}
          shadow={true}
          height={'5rem'}
        />
        <h2>Usage</h2>
        <div className="description-text">
          To add the <b>simple-react-console</b> to your site, simply import the component and add
          to your page. If you don't set a size it will fill the parent object.
        </div>
        <SyntaxHighlighter language="javascript" style={style}>{`import React from 'react';
import Console from 'simple-react-console';

function App() {
  return (
    <div style={{ maxWidth: '800px', height: '100px' }}>
      <Console/>
    </div>
  );
}

export default App;`}</SyntaxHighlighter>
        <div style={{ maxWidth: '800px', height: '100px' }}>
          <Console />
        </div>
        <div className="description-text top-space">
          Otherwise you can set the dimensions of the console directly. <b>N.B.</b> The console will
          never be wider than the parent component regardless of width set. The width is used to set
          the max-width of the console.
        </div>
        <SyntaxHighlighter
          language="javascript"
          style={style}>{`<Console width='400px' height='100px'/>`}</SyntaxHighlighter>
        <Console width="400px" height="100px" />
        <h2>setOutput</h2>
        <div className="description-text">
          setOutput allows you to set what you would like to display on the console screen.
        </div>
        <SyntaxHighlighter language="javascript" style={style}>
          {`<Console setOutput={'Hello World!'} />`}
        </SyntaxHighlighter>
        <Console height="100px" setOutput={'Hello World!'} />
        <div className="description-text top-space">
          You can pass an array of strings. Doing this will show each string on a new line.
        </div>
        <SyntaxHighlighter language="javascript" style={style}>
          {`const output = [
    {string: 'Here is line one - 1.'},
    {string: 'Here is line two - 2.'},
    {string: 'Here is line three - 3.'}
];

return(
    <Console
        setOutput={output}
    />
);`}
        </SyntaxHighlighter>
        <Console height="100px" setOutput={setOutputArray_string} />
        <h2>onComplete</h2>
        <div className="description-text">
          onComplete is triggered once a line set with setOutput has finished running
        </div>
        <SyntaxHighlighter language="javascript" style={style}>
          {`const [output, setOutput] = 
useState('After this line completes an onComplete event is triggered 0',);
const [output_n, setOutput_n] = useState(0);

const onComplete = () => {
  const n = output_n + 1;
  setOutput_n(n);
  setOutput('After this line completes an onComplete event is triggered ' + n);
}

render(
  <Console
    height="100px"
    setOutput={output}
    onComplete={onComplete}
  />
};`}
        </SyntaxHighlighter>
        <Console
          height="100px"
          setOutput={onCompleteDemo_string}
          speed={100}
          onComplete={onCompleteDemo}
          onResponse={onCompleteDemoResponse}
        />
        <h2>onResponse</h2>
        <div className="description-text">
          onResponse is triggered after a user enters text and presses the return key. An object is
          returned with:
        </div>
        <SyntaxHighlighter language="javascript" style={style}>
          {`{
    value: 'users input as a string',
    id: 'id of output, can be assigned from input'
}`}
        </SyntaxHighlighter>
        <div className="description-text">
          When setting <b>setOutput</b> assign an id to the object to have that assigned to the
          response. The following snippet sets the initial question, gets a response from the user
          and updates the console with a new message.
        </div>
        <SyntaxHighlighter language="javascript" style={style}>
          {`const [output, setOutput] = useState({string: 'Hello, what is your name?', id:'name'});

const onResponse = (response) => {
  console.log('response id: ' + response.id);
  console.log('response value: ' + response.value);

  if(response.id === 'name'){
      setOutput('Hello, ' + response.value);
  }
};

return(
  <Console
    setOutput={output}
    onResponse={onResponse}
  />
);`}
        </SyntaxHighlighter>
        <Console
          height={'100px'}
          setOutput={onResponse_output}
          onResponse={onResponse_onResponse}
        />
        <h2>setOutput - extras</h2>
        <div className="description-text">
          <b>simple-react-console</b> allows you to set some restrictions when using setOutput. You
          can only allow numbers and set a max length.
        </div>
        <SyntaxHighlighter language="javascript" style={style}>
          {`const [inputTypes, setInputTypes] = useState({
  string: 'You can only enter numbers in the following input',
  type: 'number',
  id: 'numbers',
});
const inputTypesOnResponse = (response) => {
  switch (response.id) {
    case 'numbers':
      setInputTypes({
        string: 'You can set the max chars - max = 5',
        max: 5,
        id: 'max',
      });
      break;
    case 'max':
      setInputTypes({
        string: 'You can set them both',
        max: 2,
        type: 'number',
        id: 'done',
      });
      break;
    default:
      break;
  }
};

<Console setOutput={inputTypes} height={'200px'} onResponse={inputTypesOnResponse} />`}
        </SyntaxHighlighter>
        <Console setOutput={inputTypes} height={'200px'} onResponse={inputTypesOnResponse} />
        <h2>Visuals</h2>
        <div className="description-text">
          You can modify many of the visual elements of the console. From adding shadows, headers
          and scrollbars to completely changing the colors.
        </div>
        <h3>Header and Shadows</h3>
        <SyntaxHighlighter language="javascript" style={style}>
          {`<Console showHeader={true} shadow={true} width="800px" height="250px" />`}
        </SyntaxHighlighter>
        <Console showHeader={true} shadow={true} width="800px" height="250px" />
        <h3>Colors</h3>
        <SyntaxHighlighter language="javascript" style={style}>
          {`<Console 
  showHeader={true}
  shadow={true}
  width="800px"
  height="250px"
  backgroundColor='#f7f7f7'
  textColor='#333333'
  tagColor="#57A7D9"
/>`}
        </SyntaxHighlighter>
        <Console
          showHeader={true}
          shadow={true}
          width="800px"
          height="250px"
          backgroundColor="#f7f7f7"
          textColor="#333333"
          tagColor="#57A7D9"
        />
        <h3>Tags</h3>
        <SyntaxHighlighter language="javascript" style={style}>{`<Console
  showHeader={true}
  shadow={true}
  width="800px"
  height="250px"
  tag=":>" /*Set the tag used by the console window. Default is  ~$ */
  userTag="Joe" /*changes the console name when the user is inputting*/
  consoleTag="terminal" /*changes the tag used when the console is outputting*/
/>`}</SyntaxHighlighter>
        <Console
          setOutput={'You can modify both the output and input tags'}
          showHeader={true}
          shadow={true}
          width="800px"
          height="250px"
          tag=":> "
          userTag="Joe"
          consoleTag="terminal"
          backgroundColor="#f7f7f7"
          textColor="#333333"
          tagColor="#57A7D9"
        />
        <h3>Controls</h3>
        <SyntaxHighlighter language="javascript" style={style}>{`<Console
  setOutput={controlsString}
  showHeader={true}
  shadow={true}
  width="800px"
  height="250px"
  scroll={true} /*If set as true the scroller is shown when required*/
  loop={true} /*If set to true the console will loop through an array of strings*/
  speed={60} /*Sets the speed of the transition for the output of the strings.*/
/>`}</SyntaxHighlighter>
        <Console
          setOutput={controlsString}
          showHeader={true}
          shadow={true}
          width="800px"
          height="250px"
          scroll={true}
          loop={true}
          speed={60}
        />
        <h2>Passive mode</h2>
        <div className="description-text">
          <b>simple-react-console</b> can be used as a animated text window. You can hide the tags
          and stop users from being able to type in by using the <b>passive</b> and <b>hideTags</b>{' '}
          options.
        </div>
        <SyntaxHighlighter language="javascript" style={style}>
          {`<Console
  showHeader={true}
  shadow={true}
  scroll={true}
  setOutput={aChristmasCarol}
  backgroundColor="#f7f7f7"
  textColor="#333333"
  height={'500px'}
  speed={30}
  passive={true}
  hideTags={true}
/>`}
        </SyntaxHighlighter>
        <Console
          showHeader={true}
          shadow={true}
          scroll={true}
          setOutput={passiveOutput}
          backgroundColor="#f7f7f7"
          textColor="#333333"
          height={'500px'}
          speed={30}
          passive={true}
          hideTags={true}
        />
      </div>
    </div>
  );
}

export default App;
